import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { setPaymentMethod } from 'store/slices/wizard'
import DrawerPaymentMethod from './components/drawerPaymentMethod'
import { Skeleton } from 'antd'
import mixPanel from './services/mixpanel'
import filterPaymentMethodNew from './services/filterPaymentMethodNew'

const Container = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.currencyCardBorder};
  color: ${({ theme }) => theme.colors.currencyCardColor};
  border-radius: 8px;
  padding: 18px 10px;
  margin: 10px 0px 25px 0px;
  background-color: ${({ theme }) => theme.colors.cardPaymentBackground};
`

const InputWrapper = styled.div`
  margin-top: 0px;
`

const SourceSkeleton = styled(Skeleton)`
  .ant-skeleton-content {
    display: flex;
    align-items: center;
  }
  .ant-skeleton-title {
    width: 100% !important;
  }
`
const Label = styled.label`
  font-size: 14px;
  margin-bottom: 10px;
  display: block;
  color :${({ theme }) => theme.colors.labelColorSecondary};
`

const WizardPaymentMethodWrapper = ({accountPaymentMethod, transactionFlow, isInitialLoad, isWalletAddressEntered, lastPaymentMethod, lastPaymentMethodState, apiDataFetch}) => {
  const dispatch = useDispatch()
  const { urlParams, paymentMethod, countryCode } = useSelector((state) => state.wizard)

  const [ paymentMethodData, setPaymentMethodData ] = useState([])
  const [ defaultPaymentMethod, setDefaultPaymentMethod ] = useState({})
  const [ isPaymentMethodLocked, setIsPaymentMethodLocked ] = useState(false)
  // const [ emptyPaymentMethod, setEmptyPaymentMethod ] = useState(false)

  useEffect(() => {
    if (!isInitialLoad) {
      setPaymentMethodData([])
      setDefaultPaymentMethod({})
    }
  }, [isInitialLoad, transactionFlow])

  useEffect(() => {
    if (accountPaymentMethod && countryCode) {
      initPaymentMethod(accountPaymentMethod)
    }
  }, [accountPaymentMethod])

  // useEffect(() => {
  //   if (apiDataFetch) {
  //     setEmptyPaymentMethod(accountPaymentMethod.length > 0 ? false : true)
  //   }
  // }, [accountPaymentMethod, apiDataFetch])

  const initPaymentMethod = async (accountPaymentMethod) => {
    const result = accountPaymentMethod
    setPaymentMethodData(result?.length > 0 ? result : [])
    if(result?.length > 0) {
      const paymentMethodLockedFound = result.find((payment) => payment.paymentMethod === urlParams?.paymentMethodTypeLocked) || {}
      setIsPaymentMethodLocked(paymentMethodLockedFound?.paymentMethod ? true : false)

      let defaultSelectedPaymentMethod = filterPaymentMethodNew({ result, countryCode, transactionFlow })
      const paymentMethodSelected = lastPaymentMethod?.paymentMethod || paymentMethod?.paymentMethod || paymentMethod
      const paymentMethodFound = result.find((payment) => payment.paymentMethod === (paymentMethodSelected || urlParams?.paymentMethodTypeLocked || urlParams?.paymentMethodType)) || {}
      const paymentMethodSelectedFound = paymentMethodFound?.paymentMethod ? paymentMethodFound : defaultSelectedPaymentMethod
      setDefaultPaymentMethod(paymentMethodSelectedFound)
      dispatch(setPaymentMethod(paymentMethodSelectedFound))
      lastPaymentMethodState(paymentMethodSelectedFound)
    }
  }

  return (
    <>
      <Label id="wizard-payment-method-label">{ transactionFlow === 'BUY' ? 'Payment Method' : 'Receive Funds Via' }</Label>
      <Container id="wizard-payment-method-container">
        <SourceSkeleton active loading={!defaultPaymentMethod?.name} paragraph={{rows: 0}}>
          <InputWrapper id="wizard-payment-method-input-wrapper">
            <DrawerPaymentMethod
              filterPaymentMethod={paymentMethodData}
              defaultPaymentMethod={defaultPaymentMethod || paymentMethod}
              disabled={isPaymentMethodLocked}
              header={'Select Payment Option'}
              onSelect={(c) => {
                setDefaultPaymentMethod(c)
                lastPaymentMethodState(c)
                isWalletAddressEntered(false)
                mixPanel({
                  eventName: transactionFlow === 'BUY' ? 'buy_details_payment_selection' : 'sell_details_payment_selection',
                  buySellFlow: transactionFlow,
                  payload: {
                    'paymentMethod': c
                  }
                })
              }}
              onOpen={() => {
                mixPanel({
                  eventName: transactionFlow === 'BUY' ? 'buy_details_payment_drawer_open' : 'sell_details_payment_drawer_open',
                  buySellFlow: transactionFlow
                })
              }}
              onClose={() => {
                mixPanel({
                  eventName: transactionFlow === 'BUY' ? 'buy_details_payment_drawer_back_button' : 'sell_details_payment_drawer_back_button',
                  buySellFlow: transactionFlow
                })
              }}
            />
          </InputWrapper>
        </SourceSkeleton>
      </Container>
    </>
  )
}

export default WizardPaymentMethodWrapper
